@use '../../styles/yr' as *;

.button-group {
  display: flex;

  &[data-center='true'] {
    justify-content: center;
  }
}

.button-group--hidden {
  visibility: hidden;
}

.button-group__content {
  display: flex;
  gap: size(1);
  width: 100%;

  padding: size(0.5);
  border-radius: size(1);
  background-color: var(--color-background-base);

  &[data-background='lower'] {
    background-color: var(--color-background-lower);
  }

  &[data-width='compact'] {
    width: rem(302px);
  }

  &[data-remove-gap='true'] {
    gap: 0;
  }
}

.button-group__button {
  flex: 1 1 0;
  text-decoration: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
