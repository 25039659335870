@use '../../styles/yr' as *;

// OVERRIDE(tl): We are overriding the color of the regular IconButton when used in the map.
// Hacky way to win specificity.
button.icon-button.map-icon-button:not(:disabled) {
  &[data-elevated='false'] {
    background-color: var(--color-background-base);
  }

  &[data-elevated='true'] {
    background-color: var(--color-background-base);
    box-shadow: $theme-box-shadow-elevated;
  }
}
