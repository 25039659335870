@use '../../styles/yr' as *;

$line: var(--color-fill-base);
$border: var(--color-fill-inverse);

.map-scale-line {
  user-select: none;
}

.map-scale-line__values {
  display: flex;
  color: var(--color-fill-base);
  justify-content: space-between;
  // Add a white outline around the text to make the dark values readable
  // in front of dark blue precipitation pixels.
  // We add a white text shadow in 8 directions around the text
  // in order to fake an outline.
  // prettier-ignore
  text-shadow:
    -1px -1px 0 $border, // Top left
    -1px  0   0 $border, // Top center
    -1px  1px 0 $border, // Top right
     0    1px 0 $border, // Center right
     1px  1px 0 $border, // Bottom right
     1px  0   0 $border, // Bottom center
     1px -1px 0 $border, // Bottom left
     0   -1px 0 $border; // Center left
}

// Since the border is partially transparent the line element itself is transparent
// because transparent borders are rendered on top of the element's background.
// The black background is created using a pseudo-element instead.
.map-scale-line__line {
  border-radius: 1px;
  border: 1px solid $border;
  color: transparent;
  font-size: 0;
  height: 4px;
  margin-top: 1px;
  position: relative;

  &:after {
    background-color: $line;
    border-radius: 1px;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
