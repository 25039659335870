@use '../../styles/yr' as *;

.modal-dialog {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-modal-dialog;
}

.modal-dialog__scroll-container {
  bottom: 0;
  left: 0;
  overflow: scroll;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;

  // We use `!important` to overwrite the inline style set by @nrk/core-dialog
  z-index: 1 !important;
}

.modal-dialog__flex-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  // Render in front of `.modal-dialog__backdrop`
  z-index: 1;

  // This element needs to be at least as tall as the viewport
  // in order to vertically center its dialog child when the
  // dialog is shorter than the height of the viewport.
  min-height: 100%;
}

.modal-dialog__dialog {
  background-color: var(--color-background-base);
  border: 1px solid var(--color-stroke-elevated);
  box-shadow: $theme-box-shadow-elevated;
  height: auto;
  margin: size(2);
  max-width: rem(600px);
  padding: size(2);
  position: relative;
  border-radius: size(1);
}

// Reduce padding on narrower viewports.
// We adjust padding on `.modal-dialog__header` separately because it is set to `position: fixed`
// on smaller screen and the padding needs to belong to the element itself so it will follow the element
// when the page is scrolled vertically.
.modal-dialog--large {
  .modal-dialog__dialog {
    margin: size(1) auto;
    max-width: rem(1280px);
    padding: 0 size(14) size(6) size(14);
    width: 85%;
  }

  .modal-dialog__header {
    padding-top: size(6);
  }

  @include mq-lte($mq-990) {
    .modal-dialog__dialog {
      padding: 0 size(8) size(5) size(8);
      width: 90%;
    }

    .modal-dialog__header {
      padding-top: size(5);
    }
  }

  @include mq-lte($mq-675) {
    .modal-dialog__dialog {
      padding: 0 size(6) size(4) size(6);
      width: 95%;
    }

    .modal-dialog__header {
      padding-top: size(4);
    }
  }

  @include mq-lte($mq-640) {
    .modal-dialog__dialog {
      padding: 0 size(4) size(3) size(4);
      width: 97%;
    }

    .modal-dialog__header {
      padding-top: size(3);
    }
  }

  @include mq-lte($mq-450) {
    .modal-dialog__dialog {
      margin: 0;
      padding: 0 size(2) size(2) size(2);
      width: 100%;
    }

    .modal-dialog__header {
      padding-top: size(2);
    }
  }
}

.modal-dialog__backdrop {
  @include backdrop;
}

.modal-dialog__header {
  background-color: var(--color-background-base);
  margin-bottom: size(1);
  padding-bottom: size(1);

  @include mq-lte($mq-640) {
    position: sticky;
    top: 0;
    z-index: $z-index-sticky-header;
  }
}

.modal-dialog__header-content {
  position: relative;
  padding-right: size(1) + rem(24px);
}

.modal-dialog__close-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
}

.modal-dialog__navigation {
  display: flex;
  margin-top: size(4);
}

.modal-dialog__navigation-second-button {
  margin-left: auto;
}

// OVERRIDE: We do not want padding inside the dialog
// CoastForecastHourlyDialog uses FluidTable directly inside ModalDialog
.modal-dialog__content > .fluid-table {
  padding: 0;
}
